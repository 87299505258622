.card-x{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  align-items: flex-start;
    justify-content: space-between;
    gap:10px;
    .x-card{
        width: 32%;
        flex: 1 auto;
    }
    .flex{
        display: flex;
justify-content: space-between;
align-items: center;
    }
    .card-head-x{
        background: green !important;
        color: white;
        padding: .1rem .5rem;
     
    }
    .botton-slim{
        border-radius: 10px;
        padding: 0erm .2rem;
        border:none;
        
    }
    .x-card-body, .x-card-footer{
       padding: .6rem .5rem;
       background: white;

    }
    .x-card-body{
          border-bottom: .1px solid rgb(147, 142, 142);
        padding: .6rem .5rem;
    }
    .card-head-x-bottom{
        background: white;
        margin-top: 1rem;
        border-top: 5px solid rgb(198, 162, 34);
        padding-bottom: .5rem;
        .body{
            border-top: .1px solid rgb(225, 222, 222);
            border-bottom: .1px solid rgb(225, 222, 222);
        
        }
       
    }
    .p{
        padding: 0rem .5rem;
    }
}
.shortcut{
    background: white;
    margin-top: 1rem;
    border-top: 5px solid #0494fc;
    padding-bottom: 1rem;
    h5{
        border-bottom: .1px solid rgb(147, 142, 142);
    }
  p{

      margin-top: -.5rem;
  } 
}
.bg-yellow{
    background: rgb(198, 162, 34);
}

.bg-red{
    background: red;
}
.bg-green{
    background: green;
}
.x-card-second{
background: white;
width: 32%;
padding: 5px;
.head{
    width: 100%;

    // text-align: center;
    input{
        width: 99%;
        margin: 0 auto;
        border: .1px solid rgb(147, 142, 142);
        padding: .5rem .1rem;
        }
   h4{

       text-align: center;
   } 
}
.stake{

    .stake-input{
     width: 70%;
     padding: .5rem .1rem;
     border: none;
     background: rgb(246, 241, 241);
    }
     margin-top: 2rem;
}
   
.stake-btn{
    margin: 2rem 0;
    button{
        border:none;
        padding: .5rem 2rem;
        color: white;
    }
}
}
.mb{
margin-bottom: 1rem;
}

.selected-box{
    padding: 0rem 1rem;
    margin: .5rem 0;
    text-align: center;
    justify-content: left !important;
    color: white;
}
.ml{
    margin-left: .5rem;
}
.current-head{
    justify-content: left !important;
}
.px{
    padding: .1rem .5rem;
}