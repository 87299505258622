$blue : #0494fc;

#tblMainLive {
  width: 100%;
  clear: both;
}
.live-item-icon {
  background-repeat: no-repeat;
  display: block;
  background-size: 100% 100%;
}

.live-wrapper {
  vertical-align: top;
  padding: 0;
  width: 870px;
  .topHeader {
    background: #3f444e;
    color: #fff;
    border-top: 1px solid #01D46D;
    border-bottom: 1px solid #031134;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;

    .sport-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      overflow: hidden;
      //width: calc(100% - 340px);
      .menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 12px;
        opacity: .5;
        margin-right: 10px;
        padding: 0 5px;
        height: 100%;
        cursor: pointer;

        svg {
          margin-right: 2px;
          font-size: 10px;
        }

        .live-item-icon {
          width: 12px;
          height: 12px;
          margin-right: 3px;
        }

        .sport-count {
          font-size: 11px;
          font-weight: 700;
          margin: -11px 0 0 3px;
        }

        .live-item-icon.icon_1 {
          background-image: url(../img/sports/soccer.svg);
        }

        .live-item-icon.icon_2 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_3 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_4 {
          background-image: url(../img/sports/ice-hockey.svg);
        }

        .live-item-icon.icon_5 {
          background-image: url(../img/sports/tennis.svg);
        }

        .live-item-icon.icon_6 {
          background-image: url(../img/sports/handball.svg);
        }

        .live-item-icon.icon_7 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_8 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_9 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_10 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_11 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_12 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_13 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_14 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_15 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_16 {
          background-image: url(../img/sports/football.svg);
        }

        .live-item-icon.icon_17 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_18 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_19 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_20 {
          background-image: url(../img/sports/table-tennis.svg);
        }

        .live-item-icon.icon_21 {
          background-image: url(../img/sports/cricket.svg);
        }

        .live-item-icon.icon_22 {
          background-image: url(../img/sports/basketball.svg);
        }

        .live-item-icon.icon_23 {
          background-image: url(../img/sports/volleyball.svg);
        }

        .live-item-icon.icon_254 {
          background-image: url(../img/sports/rugby.svg);
        }

        .live-item-icon.icon_83 {
          background-image: url(../img/sports/boxing.svg);
        }
      }

      .menu.selected {
        opacity: 1;
        border-bottom: 3px solid #000;
      }
    }
  }

  #live-grouped-odds {
    //margin-left: 81px;
    font-family: 'Franklin Gothic Book',Calibri,'Segoe UI',Arial,sans-serif;
    #CNWrapper {
      .wrapper {
        font-size: 14px;
        line-height: 20px;
        #divMainContentLive {
          padding: 5px;
          overflow: hidden;
          border-radius: 0;
          #divMainEventsLive {
            .tipoSport {
              line-height: 19px;
              padding: 0;
              background-color: #3f434e;
              margin-bottom: 10px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              .sport {
                position: relative;
                color: #fff;
                text-indent: 33px;
                font-size: .9em;
                font-weight: 700;
                white-space: nowrap;
                line-height: 30px;
                background-position: 8px center;
                background-repeat: no-repeat;
                cursor: pointer;
                .arrow {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  width: 30px;
                  background-image: url(../img/ev_arrow.png);
                  background-position: center -30px;
                  background-repeat: no-repeat;
                }
                .count {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 35px;
                  width: 40px;
                  text-align: right;
                  text-indent: 0;
                  font-weight: 400;
                }
              }
              .groups {
                display: block;
                margin: 0;

                .group {
                  background-color: #fafafa;
                  .titleGroup {
                    position: relative;
                    line-height: 24px;
                    font-weight: 700;
                    padding: 0 0 0 16px;
                    margin: 0 0 30px 0;
                    background-color: $blue;
                    color: #fff;
                    cursor: pointer;
                    .arrow {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      width: 30px;
                      background-image: url(../img/ev_arrow.png);
                      background-position: center -33px;
                      background-repeat: no-repeat;
                    }
                    .count {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 35px;
                      width: 40px;
                      text-align: right;
                      text-indent: 0;
                      font-weight: 400;
                    }
                  }
                  .events {
                    .item:nth-child(1){
                      margin-top:-30px;
                    }
                    .item {
                      position: relative;
                      height: 40px;
                      padding: 0;
                      background: #e6e6e6;
                      background: -moz-linear-gradient(top,#fdfdfd 0,#e6e6e6 100%);
                      background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#fdfdfd),color-stop(100%,#e6e6e6));
                      background: -o-linear-gradient(top,#fdfdfd 0,#e6e6e6 100%);
                      background: -ms-linear-gradient(top,#fdfdfd 0,#e6e6e6 100%);
                      background: linear-gradient(to bottom,#fdfdfd 0,#e6e6e6 100%);
                      border-bottom: 1px solid #c0c3cc;
                      .codPub {
                        display: block;
                        font-size: .85em;
                        position: absolute;
                        bottom: 3px;
                        left: 10px;
                        color: #6d7386;
                        line-height: 15px;
                        &:before {
                          content: '+ ';
                          display: block;
                          position: absolute;
                          left: -7px;
                          bottom: 0;
                        }
                      }
                      .evento {
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 34%;
                        color: #3f434e;
                        cursor: pointer;
                        span {
                          font-weight: 700;
                        }
                      }
                      .time {
                        position: absolute;
                        bottom: 3px;
                        left: 55px;
                        font-size: .85em;
                        color: #6d7386;
                        width: 20%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 15px;
                      }
                      .risultato {
                        position: absolute;
                        left: 35%;
                        top: 3px;
                        z-index: 2;
                        border: 1px solid #a4a8b5;
                        text-align: center;
                        padding: 0;
                        height: 32px;
                        width: 70px;
                        color: #3f434e;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 34px;
                        white-space: nowrap;
                        overflow: hidden;
                      }
                      .pnlQuote {
                        display: inline-block;
                        width: calc(78% - 2px);
                        margin: 0 0 0 42%;
                        padding-top: 3px;
                        .container {
                          .quote {
                            .mainSE {
                              float: left;
                              width: calc(16% - 16px);
                              line-height: 20px;
                              margin-left: 20px;
                              position: relative;
                              .SE {
                                display: none;
                              }
                              >div>div.OddsQuotaItemStyleTQ {
                                position: relative;
                                float: left;
                                width: calc(33% - 2px);
                                border: 1px solid #a4a8b5;
                                text-align: center;
                                div {
                                  .oddsTQ {
                                    display: none;
                                    position: absolute;
                                    top: -16px;
                                    left: 0;
                                    width: 100%;
                                    text-align: center;
                                    line-height: 14px;
                                    font-size: .7em;
                                    color: #3f434e;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                  }

                                  .oddsQ {
                                    font-size: .75em;
                                    font-weight: 700;
                                    color: #3f434e;

                                    a {
                                      display: block;
                                      line-height: 32px;
                                      font-weight: 700;
                                    }

                                    &:hover {
                                      cursor: pointer;
                                      background-color: #01D46D;
                                      background-image: none;
                                      -webkit-box-shadow: inset 0 1px 3px #000;
                                      box-shadow: inset 0 1px 3px #000;
                                      color: #fff !important;
                                    }
                                  }

                                  &.trend_2 {
                                    position: relative;

                                    &::before {
                                      position: absolute;
                                      top: 2px;
                                      right: 2px;
                                      content: "";
                                      width: 0;
                                      height: 0;
                                      border-top: 8px solid #3abd23;
                                      border-left: 8px solid transparent;
                                    }
                                  }

                                  &.trend_4 {
                                    position: relative;

                                    &::before {
                                      position: absolute;
                                      bottom: 2px;
                                      left: 2px;
                                      content: "";
                                      width: 0;
                                      height: 0;
                                      border-bottom: 8px solid #f00000;
                                      border-right: 8px solid transparent;
                                    }
                                  }
                                  &.sel {
                                    .oddsQ  {
                                      cursor: pointer;
                                      background-color: #00a957;
                                      background-image: linear-gradient(to bottom, #33dc8a 0, #33dc8a 100%);
                                      color: #000;
                                      box-shadow: inset 1px 1px 5px #000;
                                    }
                                  }
                                }
                                .blank {
                                  background-color: #eaebee;
                                  height: 32px;
                                  margin: 0;
                                  background-image: url(../img/sports/live/lock.png);
                                  background-position: center center;
                                  background-repeat: no-repeat;
                                }
                              }
                              &.o3 {
                                .OddsQuotaItemStyleTQ {
                                  width: calc(33.3% - 3px);
                                }
                              }
                              &.o2 {
                                .OddsQuotaItemStyleTQ {
                                  width: calc(50% - 3px);
                                }
                              }
                              .hndItem {
                                .hnd {
                                  display: none;
                                  float: left;
                                  width: calc(30% - 4px);
                                  text-align: center;
                                  border-radius: 0;
                                  margin-top: 1px;
                                  &:first-child {
                                    display: block;
                                    margin-right: 2px;
                                  }
                                  .hndTitle {
                                    text-align: center;
                                    line-height: 14px;
                                    font-size: .7em;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    color: #fff;
                                    background-color: #6d7386;
                                  }
                                  .hndValue {
                                    font-size: .75em;
                                    font-weight: 400;
                                    line-height: 16px;
                                    background-color: #dcdee3;
                                  }
                                }
                                .OddsQuotaItemStyleTQ {
                                  float: left;
                                  width: calc(35% - 2px);
                                  &.noOdd:first-child {
                                    margin-left: 28%;
                                  }
                                  .blank {
                                    background-color: #eaebee;
                                    height: 32px;
                                    margin: 0;
                                    background-image: url(../img/sports/live/lock.png);
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                  }
                                }
                              }
                              &:last-child {
                                .SE {
                                  border-right: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                      &:first-child {
                        .pnlQuote {
                          .container {
                            .mainSE {
                              .SE {
                                display: block;
                                position: absolute;
                                left: 0;
                                top: -34px;
                                height: 20px;
                                font-size: 11px;
                                line-height: 20px;
                                text-align: center;
                                color: #3f434e;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                              }
                              >div>div.OddsQuotaItemStyleTQ {
                                div {
                                  .oddsTQ {
                                    display: block;
                                    .hnd {
                                      display: none;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &.closed {
                    border: 0;
                    padding-bottom: 1px;
                    .titleGroup {
                      margin-bottom: 0;
                      .arrow {
                        background-position: center -3px;
                      }
                    }
                    .events {
                      display: none;
                    }
                  }
                }
              }
              &.closed {
                margin-bottom: 10px;
                border-radius: 5px;
                .groups {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
