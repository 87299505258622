.bonus{
   .flex{
        justify-content: space-between;
       display: flex;
        background: #111;
        margin-top: 2rem;
   } 
    .bonus-type-row{
        display: flex;
        width: 80%;
        justify-content: space-between;
        margin: 0 auto;
        padding-top: 3rem;
        .bonus-type-card-box{
            color: white;
            .bonus-type-card{
                border: 3px solid white;
                padding: 3rem 3rem;
                border-radius: 3%;
                text-align: center;
                .bonus-card-icon{
                    border-radius: 50%;
                    border: 2px solid white;
                    width: 100px;
                    height: 100px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3rem;
                }
                .card-title{
                    font-size: 1rem;
                    padding-top: 2rem;
                }
            }
            .bonus-btn{
                background: black;
                margin-top: 1rem;
                font-weight: 600;
                color: white;
                padding: 1rem ;
            }
            .bonus-btn:hover{
                cursor: pointer;
            }
        }
    }
    .bonus-info{
        background: white;
        padding: 1rem .5rem;
        border-radius: 5px;
    }
   .bonus-title{
    background: #313031;
    color: white;
    margin-top: 0rem;
    padding: .5rem ;
   }
   .math-card{
    width: 48%;
    .math-card-header{
        width: 100%;
        h4{

            background: #313031;

            color: white;
            padding: .5rem;
        }
    }
   }
   .full{
    width: 100%;
    h4{

        background: #313031;
        color: white;
        padding: .5rem;
    }
   }
   .table-box{
    height: 300px;
    overflow: auto;
    background: white;
    table{
        margin: 0  ;
    }
    .total{
        background: #01d46e97;
    }
   }
   .card-row{
       display: flex;
       justify-content: space-between;
       width: 100% !important;
   }
   .card-input{
    width: 23%;
    label{
        color: white;
        margin-bottom: .3rem;
        text-align: center;
        display: block;
    }
    input{
        width: 82%;
        color: white;
        text-align: center;
        border: 1px solid white;
        padding: .5rem;
        background: transparent;
    }
   }
   .bonus-card-symbol{
    font-size: 1.2rem;
    color: white;
    margin-left: 1rem;
    margin-top: 1.5rem;
   }
}


.bonus-information{
    margin-top: 4rem;
    background: black;
    .bonus-information-table{
        margin-bottom: 0 ;
        border: 1px solid white;
        th{
            text-align: center;
            border-top: 1px solid white;
        }
        th, td{
            background: transparent;
            color: white;
          }
          tr{
            background: transparent;
            border-top: 1px solid white;

          }
    }
.info-note{
    color: white;
    margin-top: .2rem;
}
ul{
    color: white;
    li{
        margin: .8rem 0;
    }
}
}